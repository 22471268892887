







import {Component} from 'vue-property-decorator';
import BaseNavigationPage from '@/components/BaseNavigationPage.vue';
import {Action, Getter} from 'vuex-class';
import {Unsubscribe} from 'firebase/firestore';
import {mixins} from 'vue-class-component';
import Navigation from '@/mixins/Navigation';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import {applicationStore} from '@/store/modules/application';
import {unsubscribeSafe} from '@/utils/helpers';

Component.registerHooks(['beforeRouteEnter'])

@Component({
  name: 'contacts2',
  components: {BaseNavigationPage}
})
export default class Contacts2 extends mixins(Navigation) {

  @Getter customersCount;
  @Getter vipCustomersCount;
  @Getter optinCustomersCount;
  @Getter personalCount;
  @Getter sharedCount;

  unsubscribeDirectory: Unsubscribe | null = null;

  get contactsCount() {
    return directoryStore.directoryContactsCount
  }

  get navItems() {
    const routName: string = this.$route.name!
    const baseItems: any[] = [
      {
        title: 'Directory',
        type: 'directory',
        name: 'directory',
        counter: this.contactsCount,
        selected: this.$route.path.includes('directory'),
        tooltip: 'Includes the organization<br>and contact list for your business,<br>which will be used to communicate<br>internally and externally'
      },
      {
        title: 'My contacts',
        type: 'personal',
        name: 'personal',
        counter: this.personalCount,
        selected: routName.includes('personal'),
        tooltip: 'Your personal contact list,<br>includes specific external<br>contacts that you added<br>to your personal contact book'
      },
      {
        title: 'Shared',
        type: 'shared',
        name: 'shared',
        counter: this.sharedCount,
        selected: routName.includes('shared'),
        tooltip: 'Any contacts that have<br>been shared with you<br>by other teammates'
      }
    ];
    if (applicationStore.isAdmin) {
      const routPath: string = this.$route.path!
      baseItems.unshift({
        title: 'Customers',
        type: 'customers',
        name: 'customers',
        counter: this.customersCount,
        group: true,
        expanded: true,
        tooltip: 'List of all customers<br>that have interacted<br>with your business',
        children: [
          {
            title: 'All',
            params: {type: 'all'},
            path: '/contacts/customers/all',
            counter: this.customersCount,
            selected: routPath.includes('all'),
            tooltip: 'All customers'
          },
          {
            title: 'VIP',
            path: '/contacts/customers/vip',
            params: {type: 'vip'},
            counter: this.vipCustomersCount,
            selected: routPath.includes('vip'),
            tooltip: 'Customers that have been<br>granted a VIP token,<br>giving them visibility<br>to hidden contacts'
          },
          {
            title: 'Opt-in',
            params: {type: 'opt-in'},
            path: '/contacts/customers/opt-in',
            counter: this.optinCustomersCount,
            selected: routPath.includes('opt-in'),
            tooltip: 'Customers with whom you<br>can initiate communication'
          }
        ]
      });
      baseItems.push({
        title: 'Partnerships',
        type: 'partnerships',
        name: 'contacts-partnerships',
        counter: 0,
        selected: routName.includes('partnerships'),
        tooltip: 'Affiliated businesses and<br>partners that are connected<br>with your business'
      })
    }
    return baseItems;
  }

  @Action fetchBusinessDirectory2;

  onNavItemSelected(navItem) {
    if (!!navItem.name) {
      this.$router.push({name: navItem.name, params: {}});
    } else {
      this.$router.push({path: navItem.path, params: navItem.params});
    }
  }

  async created() {
    this.selectPage(4);
  }

  beforeDestroy() {
    this.unsubscribeAll()
  }

  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.unsubscribeAll()
      try {
        vm.unsubscribeDirectory = await vm.fetchBusinessDirectory2();
      } catch (err) {
        console.log('CONTACTS :: failed to load directory. error = ' + err);
      }
    })
  }

  private unsubscribeAll() {
    unsubscribeSafe(this.unsubscribeDirectory)
  }
}
